import React, { useState } from 'react';
import Select from 'react-select';
import { DropdownIndicator, Option } from '../shared/Select/CustomSelect';

const RequestTypeSelect = (props) => {
  const { options, placeholder } = props;
  const [selected, setSelected] = useState();
  const mappedOptions = (
    options.map(option => ({ value: option.id, label: option.display_name || option.name, title: option.description, className: 'dcb-tooltip' }))
  );

  const controlStyle = {
    control: base => ({
      ...base, height: 28, minHeight: 28, maxHeight: 28, minWidth: 295
    }),
    menu: base => ({
      ...base, minWidth: 295, width: 'max-content'
    }),
    option: base => ({
      ...base, height: '32px', lineHeight: '32px', padding: '0 16px'
    })
  };

  const handleChange = (option) => {
    $j('.prototip').each((_i, element) => { $j(element).css('display', 'none'); });
    setSelected(option);
  };

  const filterOption = (option, searchText) => {
    const { label } = option;

    if (
      label.toLowerCase().includes(searchText.toLowerCase())
    ) return true;

    return false;
  };

  const initItemTooltips = () => {
    setTimeout(() => {
      $j('.dcb-tooltip').each((_i, element) => {
        if (element.title)
          DCBClassStyle.update(element);
      });
    }, 200);
  };

  return (
    <div className="request-types-selector-box">
      <div className="request-types-select-container shared-optimizes-select">
        <div data-testid="request-type-id" className="optimizedSelectWrap margin-top-5px">
          {mappedOptions.length > 1
            ? <Select
              inputId="requestType"
              name="" // modify it after demo
              value={selected}
              onChange={handleChange}
              maxMenuHeight={200}
              onMenuOpen={initItemTooltips}
              options={mappedOptions}
              filterOption={filterOption}
              components={{ DropdownIndicator, Option }}
              placeholder={placeholder}
              styles={controlStyle}
              className="OptimizedSelect"
              classNamePrefix="OptimizedSelect"
            />
            : <div>
              <button title={mappedOptions[0].title} className="pretty-button green create-request-btn dcb-tooltip">
                {mappedOptions[0].label}
              </button>
            </div>
          }
        </div>
      </div>
    </div>
  );
};

export default RequestTypeSelect;
