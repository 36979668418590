import { components } from 'react-select';

export const Option = props => (
  <components.Option {...props}>
    <span className={props.data.className} title={props.data.title} key={props.innerProps.key}>
      {props.data.label}
    </span>
  </components.Option>
);

export const DropdownIndicator = props => (
  <a
    tabIndex="-1"
    title={props.title || 'Show All Options'}
    className={props.class || 'ui-button ui-widget ui-state-default ui-button-icon-only ui-corner-right optimized-toggle'}
    role="button"
    data-testid={props.testId || ''}
  >
    <span className="ui-button-icon-primary ui-icon ui-icon-triangle-1-s" role="img" aria-label={props.title || 'Show all Options'} />
    <span className="ui-button-text" />
  </a>
);
