export const convertDateFormatJqueryToJs = (dateFormat) => {
    const correctMonth = 'MM';
    const correctYear = '/yy';
    const formattedDate = dateFormat.replace('mm', correctMonth).replace('yy', 'yyyy');
    if (formattedDate.includes('-yy') || formattedDate.includes(correctYear))
      return formattedDate.includes(correctMonth) ? formattedDate : formattedDate.replace('M', correctMonth);
    else if (formattedDate.includes(','))
      return formattedDate.includes('MM') ? formattedDate.replace('MM', 'MMMM') : formattedDate.replace('M', 'MMM');
  
    return formattedDate.replace('-y', '-yy').replace('/y', correctYear);
};